<template>
    <v-card class="normal-card">
        <h2 class="m-title">
            <div class="txt"></div>
            <div class="btn-area">
                <v-btn color="primary" @click="downloadTable()">下載</v-btn>
            </div>
        </h2>
        <div id="real-table"></div>
    </v-card>
</template>

<script>
import axios from 'axios';
import config from '@/config.js';

var tabledata = [];
var table;

export default{
    name: 'Info',
    data: () => ({
    }),
    created(){
        let nav_arry_str=[];
        //檢查會員權限
        const apiurl_authMem = config.geturl(config.API.exeAuthMem,{});
        const memlogin_url = config.geturl(config.API.memLogin,{});
        axios.get(apiurl_authMem).then(response => {
            //console.log(response.data);
            if(response.data.isOk){
                let nav_str_ay = response.data.log.split(',');
                nav_str_ay.forEach(function(item){
                    nav_arry_str.push(parseInt(item));
                });
                if(!nav_arry_str.includes(9)){
                    alert("您沒有該頁權限，請重新登入");
                    location.href=memlogin_url;
                }
            }else{
                alert("您沒有該頁權限，請重新登入");
                location.href=memlogin_url;
            }
        });
    },
    mounted(){
        $(function() {
            $(".nav_mainTitle").text("屬性表");

            const apiurl=config.geturl(config.API.infostation,{});
            axios.get(apiurl).then(response => {
                if(response.data !== null && response.data !== ""){
                    tabledata = response.data;
                    //console.log(tabledata);
                    table = new Tabulator("#real-table", {
                        height:"auto",
                        locale:"zh-tw",
                        langs:{
                            "zh-tw":{
                                "pagination":{
                                    "page_size":"頁數", //label for the page size select element
                                    "first":"第一頁", //text for the first page button
                                    "first_title":"第一頁", //tooltip text for the first page button
                                    "last":"最後一頁",
                                    "last_title":"最後一頁",
                                    "prev":"上一頁",
                                    "prev_title":"上一頁",
                                    "next":"下一頁",
                                    "next_title":"下一頁",

                                },
                            }
                        },
                        data:tabledata,           //load row data from array
                        layout:"fitColumns",      //fit columns to width of table
                        responsiveLayout:"hide",  //hide columns that dont fit on the table
                        tooltips:true,            //show tool tips on cells
                        addRowPos:"top",          //when adding a new row, add it to the top of the table
                        history:true,             //allow undo and redo actions on the table
                        pagination:"local",       //paginate the data
                        paginationSize:50,         //allow 7 rows per page of data
                        paginationSizeSelector:true,
                        //paginationCounter:"rows", //display count of paginated rows in footer
                        movableColumns:true,      //allow column order to be changed
                        resizableRows:true,       //allow row order to be changed
                        columns:[
                            {title:"編號", field:"id", sorter:"string", headerFilter:true, headerFilterPlaceholder:"篩選編號"},
                            {title:"測站名稱", field:"device", sorter:"string", headerFilter:true, headerFilterPlaceholder:"篩選測站"},
                            {title:"經度", field:"lat", sorter:"string"},
                            {title:"緯度", field:"lon", sorter:"string"},
                            {title:"高度", field:"alt", sorter:"string"},
                            {title:"監測地區", field:"area", sorter:"string"},
                            {title:"監測種類", field:"areatype", sorter:"string"},
                            // {title:"當前版本", field:"current_board_version", sorter:"string"},
                            // {title:"當前PM模組版本", field:"current_pm_module_version", sorter:"string"},
                        ],
                        
                        //autoColumns:true,
                    });
                }

            });
            


        })
    },
    methods:{
        downloadTable(){
            table.download("csv", "屬性表.csv", {bom:true});
        }
    }
}
</script>